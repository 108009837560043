export const skills = [
  "Select Skill",
  "JavaScript",
  "Python",
  "Java",
  "HTML",
  "CSS",
  "React.js",
  "Node.js",
  "Angular",
  "TypeScript",
  "SQL",
  "Git",
  "C#",
  "MongoDB",
  "Express.js",
  "Redux",
  "RESTful API Design",
  "Docker",
  "Kubernetes",
  "AWS",
  "Azure",
  "GCP (Google Cloud Platform)",
  "PHP",
  "Django",
  "Flask",
  "Ruby",
  "Ruby on Rails",
  "Bootstrap",
  "Sass",
  "GraphQL",
  "Vue.js",
  "ASP.NET",
  ".NET Core",
  "Spring Framework",
  "Hibernate",
  "jQuery",
  "Webpack",
  "Babel",
  "Jenkins",
  "Travis CI",
  "Selenium",
  "Cypress",
  "JIRA",
  "Confluence",
  "Agile Methodologies",
  "Scrum",
  "Kanban",
  "Continuous Integration (CI)",
  "Continuous Deployment (CD)",
  "Microservices",
  "GraphQL",
  "Terraform",
  "Puppet",
  "Chef",
  "Ansible",
  "Agile Project Management",
  "DevOps",
  "Machine Learning",
  "Artificial Intelligence",
  "Natural Language Processing (NLP)",
  "Data Science",
  "Big Data",
  "Hadoop",
  "Spark",
  "TensorFlow",
  "Keras",
  "PyTorch",
  "Reinforcement Learning",
  "Computer Vision",
  "Deep Learning",
  "Neural Networks",
  "Flask",
  "Django REST Framework",
  "FastAPI",
  "Laravel",
  "CodeIgniter",
  "Yii",
  "Symfony",
  "Express.js",
  "NestJS",
  "LoopBack",
  "Flask",
  "Falcon",
  "GraphQL Yoga",
  "Apollo Server",
  "Prisma",
  "RESTful APIs",
  "GraphQL APIs",
  "OAuth",
  "JWT (JSON Web Tokens)",
  "OpenAPI Specification (formerly Swagger)",
  "gRPC",
  "Protocol Buffers (protobuf)",
  "RabbitMQ",
  "Kafka",
  "Redis",
  "Memcached",
  "Elasticsearch",
  "Solr",
  "Postman",
  "SOAP",
];

export const jobCategories = [
  "Software Development",
  "Web Development",
  "Mobile App Development",
  "Database Administration",
  "Network Administration",
  "Systems Administration",
  "Cloud Computing",
  "Cybersecurity",
  "Data Analysis",
  "Data Engineering",
  "Data Science",
  "Machine Learning",
  "Artificial Intelligence",
  "DevOps",
  "Quality Assurance",
  "UI/UX Design",
  "Product Management",
  "Project Management",
  "Business Analysis",
  "Technical Support",
  "IT Consulting",
  "Digital Marketing",
  "Content Writing",
  "Graphic Design",
  "Video Editing",
  "Photography",
  "Animation",
  "Illustration",
  "3D Modeling",
  "Motion Graphics",
  "Game Development",
  "Augmented Reality (AR)",
  "Virtual Reality (VR)",
  "Blockchain Development",
  "Cryptocurrency",
  "Fintech",
  "Healthcare IT",
  "E-commerce",
  "Financial Services",
  "Banking",
  "Insurance",
  "Education Technology",
  "Legal Technology",
  "Real Estate Technology",
  "Automotive Technology",
  "Aerospace Technology",
  "Industrial Technology",
  "Environmental Technology",
  "Renewable Energy Technology",
  "Fashion Technology",
];
